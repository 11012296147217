<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-green-50">
    <div
      class="
        max-w-7xl
        mx-auto
        py-16
        px-4
        sm:px-6
        lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8
      "
    >
      <div>
        <h2
          class="text-base font-semibold text-green-600 uppercase tracking-wide"
        >
          Everything you need
        </h2>
        <p class="mt-2 text-3xl font-extrabold text-green-00">
          Learn everything right here, right now!
        </p>
        <p class="mt-4 text-lg text-green-600">
          Build your knowledge on a rock-solid foundation that will last a
          lifetime.<br /><br />
          We teach you everything you need to succeed in your aviation exams for
          today, tomorrow, in the future, to weather any storm.
        </p>
      </div>
      <div class="mt-12 lg:mt-0 lg:col-span-2">
        <dl
          class="
            space-y-10
            sm:space-y-0
            sm:grid
            sm:grid-cols-2
            sm:grid-rows-4
            sm:grid-flow-col
            sm:gap-x-6
            sm:gap-y-10
            lg:gap-x-8
          "
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <CheckIcon
                class="absolute h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <p class="ml-9 text-lg leading-6 font-medium text-green-700">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-9 text-base text-green-800">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/24/outline";

const features = [
  {
    name: "Exam Fundamentals",
    description:
      "ExamRevolution helps you to plan your studying, identify problems, summarize the correct materials and build your study system.",
  },
  {
    name: "Questions & Answers",
    description:
      "Ask questions, solve problems, develop a solution-driven mindset, get help 24/7/365. We have unorthodox professional results-driven mentors.",
  },
  {
    name: "Mindset Training",
    description:
      "Psychology, human behaviour, habits, memory, routines – discover how to hack your mind for resounding success.",
  },
  {
    name: "Subject Summaries",
    description:
      "Get summarized information to use as quick reference when preparing for your exams. Textbooks are old school. Clear summarized information is provided to save you time. Work smarter, not harder!",
  },
  {
    name: "Subject Explanations",
    description:
      "We believe in teaching that fosters a deeper understanding - right from the basics to the most complex.",
  },
  {
    name: "Exam Strategy",
    description:
      "Strategy is everything! We'll help you develop a solid exam strategy. Craft, plan and execute with great success.",
  },
  {
    name: "Exam Manuals",
    description:
      "Do you know what to expect in the exam room? We provide you with all the materials and show you how to use them.",
  },
  {
    name: "Problem Solving",
    description:
      "How to think clearly, solve problems quickly and see ten moves ahead with precision.",
  },
];

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      features,
    };
  },
};
</script>

<template>
  <div class="h-screen bg-green-50">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style></style>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-green-50" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <svg
            width="3098"
            height="334"
            viewBox="0 0 3098 334"
            class="h-10 w-full sm:w-auto"
            alt="ExamRevolution"
          >
            <path
              d="M171 191c0-15-6-27-17-36-10-9-23-14-39-14-14 0-27 5-37 14-9 8-16 21-18 36h111Zm59 40H60c1 17 7 30 17 40 11 9 24 14 39 14 22 0 37-10 46-28h64c-7 22-20 41-39 55s-42 22-70 22c-22 0-43-5-60-15-18-10-32-24-42-43-10-18-15-39-15-63s5-45 15-63 23-32 41-42 38-15 61-15c22 0 42 5 60 14 17 10 31 23 40 41 10 17 15 38 15 60l-2 23Z"
              fill="#242322"
            />
            <path
              d="m385 330-48-73-43 73h-63l76-117-77-116h67l47 72 43-72h63l-76 116 77 117h-66Z"
              fill="#242322"
            />
            <path
              d="M624 177a59 59 0 0 0-53-32 60 60 0 0 0-53 31c-6 10-9 23-9 37s3 26 9 36c5 11 13 19 22 24a61 61 0 0 0 84-23c6-10 8-22 8-37 0-14-2-26-8-36Zm-161-27a101 101 0 0 1 92-57c18 0 33 4 46 10 13 8 24 16 31 27V97h60v233h-60v-34c-7 11-18 20-31 27s-29 11-46 11c-20 0-38-5-54-15-16-11-29-25-38-43a146 146 0 0 1 0-126Zm626-30c18 17 26 42 26 73v137h-59V201c0-18-4-32-13-42-10-9-22-14-38-14s-29 5-38 14c-10 10-14 24-14 42v129h-59V201c0-18-5-32-14-42-9-9-22-14-38-14s-29 5-39 14c-9 10-14 24-14 42v129h-59V97h59v28c8-10 18-18 30-23 11-6 25-9 39-9 18 0 34 4 49 12 14 8 25 19 33 33a94 94 0 0 1 80-45c28 0 52 9 69 27Z"
              fill="#242322"
            />
            <path
              d="M1241 104c12-7 26-11 42-11v62h-16c-18 0-32 5-42 13-9 9-14 24-14 46v116h-59V97h59v36c8-12 18-22 30-29Zm218 87c0-15-6-27-16-36-11-9-24-14-39-14s-27 5-37 14c-10 8-16 21-19 36h111Zm59 40h-170c1 17 7 30 18 40 10 9 23 14 38 14 22 0 37-10 47-28h63c-6 22-19 41-38 55-20 14-43 22-71 22-22 0-42-5-60-15s-32-24-42-43c-10-18-15-39-15-63s5-45 15-63a110 110 0 0 1 102-57c22 0 42 5 60 14 17 10 31 23 41 41a143 143 0 0 1 13 83Zm123 45 59-179h63l-86 233h-72l-86-233h63l59 179Zm270-1c9-6 17-13 22-24 6-10 9-23 9-38 0-22-6-39-18-51a57 57 0 0 0-42-18c-17 0-31 6-43 18-11 12-17 29-17 51s6 40 17 51c11 12 25 18 42 18 10 0 20-2 30-7Zm-91 44c-18-10-32-24-42-43-10-18-16-39-16-63s6-45 16-63a115 115 0 0 1 104-57c23 0 43 5 61 15 19 10 33 24 44 42 10 18 15 39 15 63a118 118 0 0 1-60 106c-18 10-39 15-61 15-23 0-43-5-61-15Zm266-300v311h-59V19h59Zm259 78v233h-60v-30c-7 11-17 18-29 24s-26 9-40 9c-18 0-34-4-48-12-14-7-25-19-33-34s-12-33-12-53V97h59v128c0 19 4 33 13 43 10 10 22 15 38 15 17 0 29-5 39-15 9-10 13-24 13-43V97h60Zm108 48v113c0 8 1 13 5 17s10 5 19 5h28v50h-37c-50 0-75-24-75-73V145h-28V97h28V39h60v58h52v48h-52Zm134-48v233h-59V97h59Zm-55-38c-7-6-10-15-10-24 0-10 3-18 10-25s16-10 26-10 19 3 26 10 10 15 10 25c0 9-3 18-10 24-7 7-16 10-26 10s-19-3-26-10Zm228 216c9-6 17-13 22-24 6-10 9-23 9-38 0-22-6-39-18-51a57 57 0 0 0-42-18c-17 0-31 6-43 18-11 12-17 29-17 51s6 40 17 51c11 12 25 18 42 18 10 0 20-2 30-7Zm-91 44c-18-10-32-24-42-43-10-18-15-39-15-63s5-45 15-63a115 115 0 0 1 104-57c23 0 43 5 61 15 19 10 33 24 44 42 10 18 15 39 15 63a118 118 0 0 1-60 106c-18 10-39 15-61 15-23 0-43-5-61-15Zm403-199c17 17 26 42 26 73v137h-59V201c0-18-5-33-14-43-9-9-22-15-38-15s-29 6-38 15c-10 10-14 25-14 43v129h-59V97h59v29a89 89 0 0 1 70-33c28 0 50 9 67 27Z"
              fill="#29A69F"
            />
          </svg>
          <p class="text-gray-500 text-base">
            Transforming the way pilots study.
          </p>
          <div class="flex space-x-6">
            <a
              v-for="item in navigation.social"
              :key="item.name"
              :href="item.href"
              target="_void"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Courses
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.courses" :key="item.name">
                  <router-link
                    :to="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                    >{{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Support
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.support" :key="item.name">
                  <router-link
                    :to="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                    >{{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Company
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.company" :key="item.name">
                  <router-link
                    :to="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                    >{{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Legal
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.legal" :key="item.name">
                  <router-link
                    :to="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-sm text-gray-400 xl:text-center">
          &copy; COPYRIGHT 2022 - EXAMREVOLUTION.COM. ALL RIGHTS RESERVED.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from "vue";

const navigation = {
  courses: [
    { name: "I.R.", href: "/courses/ir" },
    { name: "PPL", href: "/courses/ppl" },
    { name: "CPL", href: "/courses/cpl" },
  ],
  support: [
    { name: "Contact Us", href: "/contact" },
    { name: "Book a Call", href: "/book" },
  ],
  company: [
    // { name: "Blog", href: "/blog" },
    { name: "About", href: "/about" },
    { name: "Team", href: "/team" },
  ],
  legal: [
    { name: "Privacy", href: "/privacy" },
    { name: "Terms", href: "/terms" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/examrevolutiondotcom",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/exam.revolution/?hl=en",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};

export default {
  setup() {
    return {
      navigation,
    };
  },
};
</script>

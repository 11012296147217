<template>
  <Head>
    <title>ExamRevolution - Online SACAA PPL, CPL and IR Groundschool</title>
    <base href="/base" />
    <meta
      name="description"
      content="ExamRevolution offers the best smart SACAA PPL, CPL and I.R groundschool training courses for pilots. We provide pilot mentoring and learning material for pilots wanting to pass their aviation exams easily."
    />
    <html lang="en-US" class="theme-dark" />
    <meta property="og:title" content="Home - ExamRevolution" />
    <meta property="og:type" content="product" />
    <meta property="og:url" content="https://www.examrevolution.com" />
    <meta
      property="og:image"
      content="https://ik.imagekit.io/getwings/ExamRevolution_Site/social_SSW4L8Pdi?ik-sdk-version=javascript-1.4.3&updatedAt=1651779353203"
    />
  </Head>
  <div>
    <Navbar />
    <LogoCloud />
    <FeatureTestimonial />
    <FeatureList />
    <Stats />
    <Products />
    <Testimonials />
    <CTAHome />
    <FooterComponent />
  </div>
</template>

<script>
import Navbar from "@/components/NavbarComponent.vue";
import LogoCloud from "@/components/LogoCloud.vue";
import Products from "@/components/ProductsComponent.vue";
import FeatureTestimonial from "@/components/FeatureTestimonial.vue";
import FeatureList from "@/components/FeatureList.vue";
import Stats from "@/components/StatsComponent.vue";
import Testimonials from "@/components/Testimonials.vue";
import CTAHome from "@/components/CTAHome.vue";
import FooterComponent from "@/components/FooterComponent.vue";
// @ is an alias to /src
export default {
  components: {
    Navbar,
    LogoCloud,
    Products,
    FeatureTestimonial,
    FeatureList,
    Stats,
    Testimonials,
    CTAHome,
    FooterComponent,
  },
};
</script>

<style scoped></style>

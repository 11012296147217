<template>
  <div class="bg-green-50" ref="testimony">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <h2
          class="
            text-3xl
            font-extrabold
            tracking-tight
            sm:text-4xl
            text-green-800
          "
        >
          Testimonies
        </h2>

        <ul
          role="list"
          class="
            space-y-12
            lg:grid
            lg:grid-cols-2
            lg:items-start
            lg:gap-x-8
            lg:gap-y-12
            lg:space-y-0
          "
        >
          <li v-for="person in people" :key="person.name">
            <div
              class="
                space-y-4
                sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0
                lg:gap-8
              "
            >
              <div
                class="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4"
              >
                <img
                  class="object-cover shadow-lg rounded-lg"
                  :src="person.imageUrl"
                  alt=""
                />
              </div>
              <div class="sm:col-span-2">
                <div class="space-y-1">
                  <div
                    class="
                      text-lg
                      leading-6
                      font-medium
                      space-y-1
                      text-green-800
                    "
                  >
                    <h3>{{ person.name }}</h3>
                  </div>
                  <div class="text-lg">
                    <p class="text-gray-400">{{ person.bio }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const people = [
  {
    name: "Megan Nel",
    role: "PPL & CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/megan-resized_Ejgl8svcE?ik-sdk-version=javascript-1.4.3&updatedAt=1651778803225",
    bio: '"I got 92% on my first try - using ExamRevolution! Super user friendly and an actually enjoyable way to study, would 200% recommend to all, regardless of where you are in your training!"',
  },
  {
    name: "Joshua Davies",
    role: "CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/joshua-resize_tDQoJt3Ca?ik-sdk-version=javascript-1.4.3&updatedAt=1651778359796",
    bio: '"I had just started my CPL license and was struggling with time and passing the exams, after failing two exams consecutively I joined the ExamRevolution team and haven’t looked back since! They helped me pass all my CPL exams and I am currently on the IR course now!"',
  },
  {
    name: "Cailyn Husband",
    role: "PPL Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/cailyn-resized_9pighE7yn?ik-sdk-version=javascript-1.4.3&updatedAt=1651778233189",
    bio: '"Before I signed up with ExamRevolution, I thought my CPL exams were going to be a real tough one to get through, but after passing all exams first attempt and even getting 100% for Navigation, I would recommend ExamRevolution to student pilots ANY and EVERY day, and then do it all over again!"',
  },
  {
    name: "Keian Bonafede",
    role: "CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/keian-resize_jtB_XQYNN?ik-sdk-version=javascript-1.4.3&updatedAt=1651778300887",
    bio: '"I can honestly say it is the best online course out there! ExamRevolution has great mentors that are always willing to help no matter what! You can’t go wrong with ExamRevolution."',
  },
  {
    name: "Sufiyaan Shaik",
    role: "CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/syfiyaan-resized_8LYJrwRrf?ik-sdk-version=javascript-1.4.3&updatedAt=1651778486797",
    bio: '"ExamRevolution has given me the tools required to pass these exams. Their in-depth explanations help in understanding the work and figuring answers out through methodology rather than guesswork."',
  },
  {
    name: "Nicole Mienie",
    role: "CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/nicole-2_ohv7wmPVpG?ik-sdk-version=javascript-1.4.3&updatedAt=1651844131921",
    bio: '"ExamRevolution has given me the confidence and tools to pass my flying exams easier, quicker and with really good results. Top program!"',
  },
  {
    name: "Willem Naudé",
    role: "CPL/IR Student",
    imageUrl:
      "https://ik.imagekit.io/getwings/ExamRevolution_Site/willem-resized_jGUW91ALQ?ik-sdk-version=javascript-1.4.3&updatedAt=1651778593235",
    bio: '"ExamRevolution helped to stop my exams from lapsing. There is no faster and better way than this program. Don\'t waste time, just join!"',
  },
];

export default {
  name: "TestimonialsComponent",
  setup() {
    return {
      people,
    };
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-white sm:text-center">
          Our Courses
        </h1>
        <p class="mt-5 text-xl text-gray-100 sm:text-center">
          ExamRevolution offers 3 packages that cater for every student pilot.
          <br />Whether you are studying to fly a single-engine plane in your
          spare time, or to fly luxury private jets for celebrities, we have you
          covered.
        </p>
      </div>
      <div
        class="
          mt-12
          space-y-4
          sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6
          lg:max-w-4xl lg:mx-auto
          xl:max-w-none xl:mx-0 xl:grid-cols-3
        "
      >
        <div
          v-for="tier in tiers"
          :key="tier.name"
          class="
            border border-gray-200
            rounded-lg
            shadow-sm
            divide-y divide-gray-200
          "
        >
          <div class="p-6">
            <div class="text-lg leading-6 font-medium text-white">
              {{ tier.name }}
            </div>
            <p class="mt-4 text-sm text-white">{{ tier.description }}</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-white">{{
                tier.nameShort
              }}</span>
              {{ " " }}
              <span class="text-base font-medium text-gray-100"
                >/ {{ tier.time }}</span
              >
            </p>
            <router-link
              :to="tier.more"
              class="
                mt-8
                block
                w-full
                bg-white
                border border-gray-800
                rounded-md
                py-2
                text-sm
                font-semibold
                text-green text-center
                hover:bg-green-50
              "
              >Read More</router-link
            >
            <router-link
              :to="tier.href"
              class="
                mt-2
                block
                w-full
                bg-green
                border border-gray-800
                rounded-md
                py-2
                text-sm
                font-semibold
                text-white text-center
                hover:bg-green-600
              "
              >Sign up for {{ tier.name }}</router-link
            >
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3
              class="text-xs font-medium text-gray-100 tracking-wide uppercase"
            >
              What's included
            </h3>
            <ul role="list" class="mt-6 space-y-4">
              <li
                v-for="feature in tier.includedFeatures"
                :key="feature"
                class="flex space-x-3"
              >
                <CheckIcon
                  class="flex-shrink-0 h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                <span class="text-sm text-gray-100">{{ feature }}</span>
              </li>
            </ul>
            <h3
              v-if="tier.subjects"
              class="
                text-xs
                font-medium
                text-gray-100
                tracking-wide
                uppercase
                pt-8
              "
            >
              Subjects Offered
            </h3>
            <ul role="list" class="mt-6 space-y-4 pl-5 list-disc">
              <li
                v-for="subject in tier.subjects"
                :key="subject"
                class="space-x-3 text-sm text-gray-100"
              >
                {{ subject }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/24/solid";

const tiers = [
  {
    name: "I.R. Pilot Mentoring Course",
    href: "/book",
    nameShort: "I.R.",
    time: "8 weeks",
    description:
      "Pass your Instrument Rating exam within 8 weeks or less, regardless of your experience.",
    includedFeatures: [
      "In-Depth Video Tutorials",
      "Question and Answer Forum",
      "Semi-Weekly Q&A Calls",
      "Quick Reference Notes",
      "Study Help",
      "Mock Exams",
    ],
    more: "/courses/ir",
  },
  {
    name: "PPL Pilot Mentoring Course",
    href: "/book",
    nameShort: "PPL",
    time: "8 weeks",
    description:
      "Pass your Private Pilot License exams within 8 weeks or less, without failing a single subject.",
    includedFeatures: [
      "In-Depth Video Tutorials",
      "Question and Answer Forum",
      "Weekly Q&A Calls",
      "Quick Reference Notes",
      "Study Help",
      "Mock Exams",
    ],
    subjects: [
      "Principles of Flight",
      "Air Law",
      "Human Performance",
      "Meteorology",
      "Flight Planning",
      "Navigation",
      "Aircraft Technical and General",
    ],
    more: "/courses/ppl",
  },
  {
    name: "CPL Pilot Mentoring Course",
    href: "/book",
    nameShort: "CPL",
    time: "6 months",
    description:
      "Pass all your Commercial Pilot License exams within 6 months or less, without failing a single subject.",
    includedFeatures: [
      "In-Depth Video Tutorials",
      "Question and Answer Forum",
      "Semi-Weekly Q&A Calls",
      "Quick Reference Notes",
      "Study Help",
      "Mock Exams",
    ],
    subjects: [
      "Air Law",
      "Human Performance",
      "Meteorology",
      "Flight Planning",
      "Navigation",
      "Aircraft Technical and General",
      "Radio Aids",
      "Instruments and Electronics",
    ],
    more: "/courses/cpl",
  },
];

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      tiers,
    };
  },
};
</script>

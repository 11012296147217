<template>
  <div class="bg-green-50">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-14 sm:px-6 lg:px-8">
      <div class="lg:grid lg:grid-cols-2 lg:gap-8">
        <div
          class="
            max-w-md
            text-center
            mx-auto
            lg:max-w-xl lg:text-left lg:items-center
          "
        >
          <h2 class="text-3xl font-extrabold text-green-700">
            Leading flight-schools partner with ExamRevolution.
          </h2>
          <h2 class="text-green-700 pt-10 text-base hidden lg:block">
            Interested in using our courses for your flight school?
            <router-link to="contact" class="underline cursor-pointer"
              >Contact us here
            </router-link>
          </h2>
        </div>
        <div class="flow-root self-center mt-8 lg:mt-0">
          <div
            class="
              -mt-4
              -ml-8
              flex flex-wrap
              justify-center
              lg:-ml-4 lg:grid lg:
              grid-cols-2
            "
          >
            <div
              class="
                mt-2
                ml-8
                flex flex-grow flex-shrink-0
                justify-center
                lg:flex-grow-0 lg:ml-4
              "
            >
              <img
                src="@/assets/svg/stellenbosch-flying-club.svg"
                class="h-28"
                alt="Stellenbosch Flying Club"
              />
            </div>
            <div
              class="
                mt-4
                ml-8
                flex flex-grow flex-shrink-0
                justify-center
                lg:flex-grow-0 lg:ml-4
              "
            >
              <img
                class="h-24"
                src="@/assets/svg/eagle.svg"
                alt="Eagle Flight Academy"
              />
            </div>
            <div
              class="
                mt-4
                ml-8
                flex flex-grow flex-shrink-0
                justify-center
                lg:flex-grow-0 lg:ml-4
              "
            >
              <img
                class="h-24"
                src="@/assets/svg/pmb-aero-club.svg"
                alt="Pietermaritzburg Aero Club"
              />
            </div>
            <div
              class="
                mt-4
                ml-8
                flex flex-grow flex-shrink-0
                justify-center
                lg:flex-grow-0 lg:ml-4
              "
            >
              <img
                class="h-20"
                src="@/assets/svg/moya.svg"
                alt="Mach1 Aviation Acadamy"
              />
            </div>
            <div
              class="
                mt-4
                ml-8
                flex flex-grow flex-shrink-0
                justify-center
                lg:flex-grow-0 lg:ml-4
              "
            >
              <img
                class="h-24"
                src="@/assets/svg/rafsa.svg"
                alt="RAFSA"
              />
            </div>
          </div>
        </div>
        <h2 class="text-green-700 text-center pt-10 text-sm lg:hidden">
          Interested in using our courses for your flight school?
          <router-link to="contact" class="underline cursor-pointer"
            >Contact us here
          </router-link>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import SFCLogo from "@/assets/svg/stellenbosch-flying-club.svg";
export default {
  setup() {
    SFCLogo;
  },
};
</script>

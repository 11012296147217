<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray">
    <div
      class="
        max-w-7xl
        mx-auto
        py-12
        px-4
        sm:px-6
        lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between
      "
    >
      <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
        <span class="block">Ready to jump in?</span>
        <span class="block text-green">Book a call with us.</span>
      </h2>
      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div class="inline-flex rounded-md shadow">
          <router-link
            to="/book"
            class="
              inline-flex
              items-center
              justify-center
              px-5
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              text-white
              bg-green-600
              hover:bg-green-700
            "
          >
            Get started
          </router-link>
        </div>
        <div class="ml-3 inline-flex rounded-md shadow">
          <router-link
            to="/about"
            class="
              inline-flex
              items-center
              justify-center
              px-5
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              text-green-600
              bg-white
              hover:bg-green-50
            "
          >
            Learn more
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

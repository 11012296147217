<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-gray pt-16 pb-32 overflow-hidden">
    <div class="relative">
      <div
        class="
          lg:mx-auto
          lg:max-w-7xl
          lg:px-8
          lg:grid
          lg:grid-cols-2
          lg:grid-flow-col-dense
          lg:gap-24
        "
      >
        <div
          class="
            px-4
            max-w-xl
            mx-auto
            sm:px-6
            lg:py-16 lg:max-w-none lg:mx-0 lg:px-0
          "
        >
          <div>
            <div>
              <span
                class="
                  h-12
                  w-12
                  rounded-md
                  flex
                  items-center
                  justify-center
                  bg-green
                "
              >
                <img
                  src="@/assets/svg/plane-icon.svg"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-white">
                A Pilot's One-Stop Study-Hub.
              </h2>
              <p class="mt-4 text-lg text-gray-100">
                We get it. Pilot exams are tough.<br /><b
                  >But it doesn't have to be!</b
                ><br /><br />ExamRevolution puts the fun back in flying by
                allowing you to study for your written pilot exams - anytime,
                anywhere, and as much as you need to.
              </p>
              <div class="mt-6">
                <router-link
                  to="/book"
                  class="
                    inline-flex
                    px-4
                    py-2
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    shadow-sm
                    text-white
                    bg-green-600
                    hover:bg-green-700
                  "
                >
                  Get started
                </router-link>
              </div>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-400 pt-6">
            <blockquote>
              <div>
                <p class="text-base text-gray-100">
                  &ldquo;I was looking for a system that could guarantee
                  results. Nothing was up for the challenge - except for
                  ExamRevolution. What a journey it has been!&rdquo;
                </p>
              </div>
              <footer class="mt-3">
                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0">
                    <img
                      class="h-6 w-6 rounded-full"
                      src="https://ik.imagekit.io/getwings/ExamRevolution_Site/phillip_b7ngaj8pW?ik-sdk-version=javascript-1.4.3&updatedAt=1651798377845"
                      alt=""
                    />
                  </div>
                  <div class="text-base font-medium text-gray-200">
                    Phillip Nieuwoudt, PPL Student
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div
            class="
              pl-4
              -mr-48
              sm:pl-6
              md:-mr-16
              lg:px-0 lg:m-0 lg:relative lg:h-full
            "
          >
            <img
              class="
                w-full
                rounded-xl
                shadow-xl
                ring-1 ring-black ring-opacity-5
                lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none
              "
              src="https://ik.imagekit.io/getwings/ExamRevolution_Site/cessna-resized_YX2AYViVs?ik-sdk-version=javascript-1.4.3&updatedAt=1651843434100"
              alt="Inbox user interface"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-24">
      <div
        class="
          lg:mx-auto
          lg:max-w-7xl
          lg:px-8
          lg:grid
          lg:grid-cols-2
          lg:grid-flow-col-dense
          lg:gap-24
        "
      >
        <div
          class="
            px-4
            max-w-xl
            mx-auto
            sm:px-6
            lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2
          "
        >
          <div>
            <div>
              <span
                class="
                  h-12
                  w-12
                  rounded-md
                  flex
                  items-center
                  justify-center
                  bg-green
                "
              >
                <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-white">
                Studying as it should be - no magic needed.
              </h2>
              <p class="mt-4 text-lg text-gray-100">
                Feeling like you don't know where to start?<br /><br />Leave
                those difficult decisions to us. <br /><br />Our methods have
                been battle-tested by many a student. Just have a look at their
                testimonials below and you'll immediately understand why.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div
            class="
              pr-4
              -ml-48
              sm:pr-6
              md:-ml-16
              lg:px-0 lg:m-0 lg:relative lg:h-full
            "
          >
            <img
              class="
                w-full
                rounded-xl
                shadow-xl
                ring-1 ring-black ring-opacity-5
                lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none
              "
              src="https://ik.imagekit.io/getwings/ExamRevolution_Site/maps_P_jypX_FO?ik-sdk-version=javascript-1.4.3&updatedAt=1651797356828"
              alt="Customer profile user interface"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SparklesIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    SparklesIcon,
  },
};
</script>
